<template>
  <div class="feedback-mask">
    <div class="feedback-content">
      <!-- {{courseId}} -->
      <h4 class="feedback-title">请您为该课程做出评价</h4>
      <div class="feedback-item">
        <p class="feedback-item-title">您愿意推荐本课程给其他朋友吗? (必选)</p>
        <a-rate
          class="pc-rate"
          v-model="score"
          allow-half
          style="font-size: 34px; color: #0a70c5"
        />
        <a-rate
          class="mob-rate"
          v-model="score"
          allow-half
          style="font-size: 18px; color: #0a70c5"
        />
      </div>
      <div class="feedback-item">
        <p class="feedback-item-title">听完本次分享有什么收获? (必填)</p>
        <a-textarea
          class="feedback-input"
          :rows="4"
          v-model="feedbackContent"
        />
      </div>
      <div class="feedback-item">
        <p class="feedback-item-title">对讲师有什么想说的?</p>
        <a-textarea class="feedback-input" :rows="4" v-model="advice" />
      </div>
      <div class="feedback-btn-wrapper">
        <button class="feedback-btn" @click="$emit('hideFeedback')">
          取消
        </button>
        <button class="feedback-btn" @click="courseFeedback">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { sid } from "@/utils/api.js";
export default {
  props: {
    courseId: Number,
    token: String,
  },
  data() {
    return {
      advice: "", // 对老师要说的话
      feedbackContent: "", // 收获
      score: 0, // 分数
    };
  },
  methods: {
    // 反馈
    courseFeedback(courseId) {
      if (this.score === 0) {
        this.$message.warning("请选择您是否愿意推荐本课程");
        return false;
      }
      if (this.feedbackContent.trim() === "") {
        this.$message.warning("请填写您的收获");
        return false;
      }
      const score = this.score * 2;
      const url = `https://www.msup.com.cn/wechat/small-program/feedback-save?schedulingId=${sid(this.global.year)}&courseId=${this.courseId}&token=${this.token}&advice=${this.advice}&feedback=${this.feedbackContent}&score=${score}`;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.success === true) {
            this.$message.success("反馈成功");
            this.$emit("hideFeedback");
          } else {
            this.$message.error("您已提交过反馈，请勿重复填写");
            this.$emit("hideFeedback");
          }
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.feedback-mask {
  // --main-color: ;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .feedback-mask {
    background: rgba(27, 27, 27, 0.3);
    .feedback-content {
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      border-radius: 7px;
      // position: fixed;
      // top: 60%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      width: 516px;
      min-height: 548px;
      text-align: center;
      padding: 45px 70px 48px;
      box-sizing: border-box;
      .feedback-title {
        margin-bottom: 20px;
        font-size: 21px;
        font-weight: 400;
        color: #050505;
      }
      .feedback-item {
        text-align: left;
        padding-top: 16px;
        .pc-rate {
          display: inline-block;
        }
        .mob-rate {
          display: none;
        }
        .feedback-item-title {
          margin-bottom: 13px;
          font-size: 17px;
          font-weight: 400;
          color: #484848;
        }
        .feedback-input {
          width: 375px;
          height: 81px;
          border: 1px solid #d6dfea;
          border-radius: 0;
          line-height: 21px;
          font-size: 15px;
        }
      }
      .feedback-btn-wrapper {
        margin-top: 36px;
        .feedback-btn {
          border: none;
          outline: none;
          margin-left: 13px;
          margin-right: 13px;
          width: 104px;
          height: 42px;
          border-radius: 4px;
          font-size: 17px;
          &:first-child {
            background: #dce5ef;
            color: #4d97d5;
          }
          &:last-child {
            background: #0a70c5;
            color: #fff;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .feedback-mask {
    background: rgba(27, 27, 27, 0.3);
    .feedback-content {
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      border-radius: 7px;
      // position: fixed;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      width: 87.6vw;
      text-align: center;
      padding: 24px 12vw 29px;
      box-sizing: border-box;
      .feedback-title {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: 400;
        color: #050505;
      }
      .feedback-item {
        text-align: left;
        padding-top: 12px;
        .pc-rate {
          display: none;
        }
        .mob-rate {
          display: inline-block;
        }
        .feedback-item-title {
          margin-bottom: 8px;
          font-size: 12px;
          font-weight: 400;
          color: #484848;
        }
        .feedback-input {
          width: 64vw;
          height: 75px;
          border: 1px solid #d6dfea;
          border-radius: 0;
          line-height: 18px;
          font-size: 12px;
        }
      }
      .feedback-btn-wrapper {
        margin-top: 25px;
        .feedback-btn {
          border: none;
          outline: none;
          margin-left: 13px;
          margin-right: 13px;
          width: 22vw;
          height: 32px;
          border-radius: 4px;
          font-size: 13px;
          &:first-child {
            background: #dce5ef;
            color: #4d97d5;
          }
          &:last-child {
            background: #0a70c5;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
